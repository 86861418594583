export default {
    data() {
        return {
            originalUploadFolderUid: ''
        }
    },
    computed: {

    },
    methods: {
        getTeamDocList({ pageNum = 1, pageSize = 10 } = {}) {
            this.pagination.pageNum = pageNum
            this.pagination.pageSize = pageSize
        },
        async beforeUpload(file) {
            if (file.status === 'ready') {
                this.addOneFile(file)
            }
            this.setShowFileUploadPanel(true)
            this.setShowFileUploadList(true)
                //
            let isRepeatName = ''

            if (file.isFolder) {
                window.canUpload = false
                    //
                if (this.originalUploadFolderUid !== file.originalFolderUid) {
                    this.originalUploadFolderUid = file.originalFolderUid
                    isRepeatName = await this.checkRepeatName(file.originalFolderName)
                }
            } else {
                window.canUpload = false
                isRepeatName = await this.checkRepeatName(file.fileRealName)
            }
            //文件大于200M取消上传
            if (file.fileSize > 200 * 1024 * 1024) {
                this.cancelUpload(file)
                this.$message({
                    type: "error",
                    message: "上传文件不能超过200M"
                })
            } else if (isRepeatName) {
                this.setShowFileUploadList(true)
                this.continueUpload(file)
            } else {
                this.continueUpload(file)
            }
        },
        fileUploadChange(file) {
            this.updateOneFile(file)
        },
        //
        fileUploadSuccess(response, file) {
            if (response.message === 'ok') {
                this.updateOneFile(file)
                if (this.fileList.findIndex(v => v.status !== 'success') ===
                    -1) {
                    this.setShowFileUploadList(true)
                }
                this.getTeamDocList({
                    pageNum: this.pagination.pageNum,
                    pageSize: this.pagination.pageSize
                })
            } else {
                file.percentage = 80
                file.status = 'saveError'
                this.$message.error('上传失败！')
            }
            window.deletePoints(file)
        },
        //
        fileExceed() {
            this.$message.error(` 文件数量过多！`)
        },
        fileUploadError(err) {
            if (err && err.message) {
                this.$message.error(err.message)
            }
        },
        //
        continueUpload(file) {
            this.$refs.fileUp2D.continueUpload(file)
        },
        //
        pauseUpload(file) {
            window.pauseUpload(file)
        },
        //
        cancelUpload(file) {
            file.statusFlag = 'cancel'
            window.cancelUpload(file)
            const index = this.fileList.findIndex(item => item.uid ===
                file.uid)
            if (index !== -1) {
                this.removeOneFile(index)
            }
        },
        //
        reUpload(file) {
            if (file.status === 'error') {
                window.continueUpload(file)
            } else {
                window.uploadSucceed(file)
            }
        },
        //
        closeUploaderList() {
            if (this.fileList.findIndex(v => v.status !== 'success') ===
                -1) {
                this.setShowFileUploadPanel(false)
                this.fileList = []
                this.setFileUploadList([])
            } else {
                this.$confirm(', ?', '', {
                    confirmButtonText: '',
                    cancelButtonText: '',
                    type: 'warning'
                }).then(() => {
                    this.setShowFileUploadPanel(false)
                    this.fileList.forEach(file => {
                        if (file.status !== 'success') {
                            file.statusFlag = 'cancel'
                            window.cancelUpload(file)
                        }
                    })
                    this.fileList = []
                    this.setFileUploadList([])
                }).catch(() => {})
            }
        }
    }
}
