<template>
  <div class="content page-box">
    <div class="list-title">
      <h4 class="title">新建合同</h4>
    </div>
    <section class="content-box">
      <el-form
        ref="creatform"
        :model="creatform"
        :rules="formRules"
        label-width="115px"
        size="small"
      >
        <!-- 基础信息 -->
        <div class="detail-card detail-card-nopadding">
          <header class="card-header">
            <h4 class="second-title">基础信息</h4>
          </header>
          <section class="card-content">
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item
                  label="合同类型:"
                  prop="contractType"
                >
                  <el-select
                    v-model="creatform.contractType"
                    style="width: 100%"
                  >
                    <template
                      v-for="(item, index) of contypeList"
                      style="left: 0px"
                    >
                      <el-option
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                      />
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="合同名称:"
                  prop="contractName"
                >
                  <el-input
                    maxlength="255"
                    placeholder="请输入合同名称"
                    v-model="creatform.contractName"
                    @keydown.enter.native.prevent="handle"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="经办人:"
                  prop="currentUserName"
                >
                  <el-input
                    maxlength="100"
                    v-model="currentUserName"
                    disabled
                  />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="经办日期:"
                  prop="contractDate"
                >
                  <el-date-picker
                    v-model="creatform.contractDate"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                    @change="dateChange"
                  >
                  </el-date-picker>
                  <span
                    class="date-tip"
                    v-show="dateTip"
                  >请注意：所选日期早于当前日期</span>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="6">
                <el-form-item
                  label="经办部门:"
                  prop="contractDept"
                >
                  <el-select
                    v-model="creatform.contractDept"
                    style="width: 100%"
                  >
                    <template v-for="(item, index) of orgList">
                      <el-option
                        :key="index"
                        :label="item.orgName"
                        :value="item.orgCode"
                      />
                    </template>
                  </el-select>
                </el-form-item>
              </el-col> -->
            </el-row>
            <el-row :gutter="20">
              <el-col :span="6" id="project-goal">
                <el-form-item
                  label="关联项目:"
                  prop="projectGoalFlag"
                >
                  <el-select
                    v-model="creatform.projectGoalFlag"
                    style="width: 100%"
                    :popper-append-to-body="false"
                    @change="correlateOptions"
                  >
                    <el-option
                      label="非项目目的"
                      value="N"
                    />
                    <el-option
                      label="项目目的"
                      value="Y"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  class="correlate"
                  key="1"
                  v-if="creatform.projectGoalFlag == 'N'"
                >
                  <el-input
                    disabled
                    placeholder="-"
                  />
                </el-form-item>
                <el-form-item
                  class="correlate"
                  prop="relevanceProjectInfo"
                  key="2"
                  v-else
                >
                  <!-- 关联项目选项 -->
                  <el-select
                    v-model="creatform.relevanceProjectInfo"
                    style="width: 100%"
                    :popper-append-to-body="false"
                    filterable
                    multiple
                  >
                    <div
                      slot=""
                      class="correlate-options"
                    >
                      <el-tabs v-model="activeName">
                        <el-tab-pane
                          label="全部"
                          name="0"
                        >
                          <template>
                            <div
                              v-loading="correlateLoading"
                              class="options-selects"
                            >
                              <el-option
                                v-for="(item, index) of correlateListAll"
                                :key="index"
                                :label="item.name"
                                :value="item.id"
                              >
                                <p class="options-line">
                                  <span
                                    class="options-name"
                                    v-maia-ellipsis
                                  >{{ item.name }}</span>
                                  <span class="options-type">{{ item.type ==='8'? '直投': item.typeName }}</span>
                                </p>
                              </el-option>
                            </div>
                          </template>
                        </el-tab-pane>
                        <!-- <el-tab-pane
                          label="直投"
                          name="1"
                        >
                          <template>
                            <div class="options-selects">
                              <el-option
                                v-for="(item, index) of correlateListDire"
                                :key="index"
                                :label="item.name"
                                :value="item.id"
                              >
                                <p class="options-line">
                                  <span
                                    class="options-name"
                                    v-maia-ellipsis
                                  >{{ item.name }}</span>
                                  <span class="options-type">{{ item.typeName }}</span>
                                </p>
                              </el-option>
                            </div>
                          </template>
                          <p
                            v-if="correlateListDire.length == 0"
                            class="el-select-dropdown__empty"
                          >暂无数据</p>
                        </el-tab-pane> -->
                        <el-tab-pane
                          label="直投"
                          name="8"
                        >
                          <template>
                            <div class="options-selects">
                              <el-option
                                v-for="(item, index) of correlateListClew"
                                :key="index"
                                :label="item.name"
                                :value="item.id"
                              >
                                <p class="options-line">
                                  <span
                                    class="options-name"
                                    v-maia-ellipsis
                                  >{{ item.name }}</span>
                                  <span class="options-type">直投</span>
                                </p>
                              </el-option>
                            </div>
                          </template>
                          <p
                            v-if="correlateListClew.length == 0"
                            class="el-select-dropdown__empty"
                          >暂无数据</p>
                        </el-tab-pane>
                        <el-tab-pane
                          label="一级基金"
                          name="4"
                        >
                          <template>
                            <div class="options-selects">
                              <el-option
                                v-for="(item, index) of correlateListStairFund"
                                :key="index"
                                :label="item.name"
                                :value="item.id"
                              >
                                <p class="options-line">
                                  <span
                                    class="options-name"
                                    v-maia-ellipsis
                                  >{{ item.name }}</span>
                                  <span class="options-type">{{ item.typeName }}</span>
                                </p>
                              </el-option>
                            </div>
                          </template>
                          <p
                            v-if="correlateListStairFund.length == 0"
                            class="el-select-dropdown__empty"
                          >暂无数据</p>
                        </el-tab-pane>
                        <el-tab-pane
                          label="二级基金"
                          name="5"
                        >
                          <template>
                            <div class="options-selects">
                              <el-option
                                v-for="(item, index) of correlateListSecondaryFund"
                                :key="index"
                                :label="item.name"
                                :value="item.id"
                              >
                                <p class="options-line">
                                  <span
                                    class="options-name"
                                    v-maia-ellipsis
                                  >{{ item.name }}</span>
                                  <span class="options-type">{{ item.typeName }}</span>
                                </p>
                              </el-option>
                            </div>
                          </template>
                          <p
                            v-if="correlateListSecondaryFund.length == 0"
                            class="el-select-dropdown__empty"
                          >暂无数据</p>
                        </el-tab-pane>
                        <el-tab-pane
                          label="股票"
                          name="6"
                        >
                          <template>
                            <div class="options-selects">
                              <el-option
                                v-for="(item, index) of correlateListStock"
                                :key="index"
                                :label="item.name"
                                :value="item.id"
                              >
                                <p class="options-line">
                                  <span
                                    class="options-name"
                                    v-maia-ellipsis
                                  >{{ item.name }}</span>
                                  <span class="options-type">{{ item.typeName }}</span>
                                </p>
                              </el-option>
                            </div>
                          </template>
                          <p
                            v-if="correlateListStock.length == 0"
                            class="el-select-dropdown__empty"
                          >暂无数据</p>
                        </el-tab-pane>
                        <el-tab-pane
                          label="债券"
                          name="2"
                        >
                          <template>
                            <div class="options-selects">
                              <el-option
                                v-for="(item, index) of correlateListBond"
                                :key="index"
                                :label="item.name"
                                :value="item.id"
                              >
                                <p class="options-line">
                                  <span
                                    class="options-name"
                                    v-maia-ellipsis
                                  >{{ item.name }}</span>
                                  <span class="options-type">{{ item.typeName }}</span>
                                </p>
                              </el-option>
                            </div>
                          </template>
                          <p
                            v-if="correlateListBond.length == 0"
                            class="el-select-dropdown__empty"
                          >暂无数据</p>
                        </el-tab-pane>
                        <el-tab-pane
                          label="控股"
                          name="3"
                        >
                          <template>
                            <div class="options-selects">
                              <el-option
                                v-for="(item, index) of correlateListHolding"
                                :key="index"
                                :label="item.name"
                                :value="item.id"
                              >
                                <p class="options-line">
                                  <span
                                    class="options-name"
                                    v-maia-ellipsis
                                  >{{ item.name }}</span>
                                  <span class="options-type">{{ item.typeName }}</span>
                                </p>
                              </el-option>
                            </div>
                          </template>
                          <p
                            v-if="correlateListHolding.length == 0"
                            class="el-select-dropdown__empty"
                          >暂无数据</p>
                        </el-tab-pane>
                        <el-tab-pane
                          label="固定资产"
                          name="7"
                        >
                          <template>
                            <div class="options-selects">
                              <el-option
                                v-for="(item, index) of correlateListFixed"
                                :key="index"
                                :label="item.name"
                                :value="item.id"
                              >
                                <p class="options-line">
                                  <span
                                    class="options-name"
                                    v-maia-ellipsis
                                  >{{ item.name }}</span>
                                  <span class="options-type">{{ item.typeName }}</span>
                                </p>
                              </el-option>
                            </div>
                          </template>
                          <p
                            v-if="correlateListFixed.length == 0"
                            class="el-select-dropdown__empty"
                          >暂无数据</p>
                        </el-tab-pane>

                      </el-tabs>
                    </div>
                    <div
                      slot="empty"
                      class="correlate-options"
                      v-loading="correlateLoading"
                    >
                      <p class="el-select-dropdown__empty">暂无数据</p>
                    </div>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="是否需要付款:"
                  prop="needPay"
                >
                  <el-radio-group v-model="creatform.needPay">
                    <el-radio label="Y">是</el-radio>
                    <el-radio label="N">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <!-- 是否供方合同 -->
              <el-col :span="6">
                <el-form-item
                  label="是否供方合同:"
                  prop="supplyStoreFlag"
                >
                  <el-radio-group v-model="creatform.supplyStoreFlag" @change="changeContractSource">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item
                  label="形成方式:"
                  prop="forType"
                >
                  <el-select
                    v-model="creatform.forType"
                    style="width: 100%"
                  >
                    <template v-for="(item, index) of forTypeList">
                      <el-option
                        :key="index"
                        :label="item.label"
                        :value="item.code"
                      />
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6" v-if="creatform.projectGoalFlag == 'Y' && projectTypeList.indexOf('3') != -1 && creatform.contractType == 'COMMON'">
                <el-form-item
                  label="所属阶段:"
                  prop="kgStage"
                >
                  <el-select v-model="creatform.kgStage">
                    <el-option label="投资阶段" value="1"/>
                    <el-option label="退出阶段" value="2"/>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6" v-if="creatform.projectGoalFlag == 'Y' && projectTypeList.indexOf('7') != -1 && creatform.contractType == 'COMMON'">
                <el-form-item
                  label="具体合同类型:"
                  prop="fixContractType"
                >
                  <el-select v-model="creatform.fixContractType">
                    <el-option label="CapEX合同" value="1"/>
                    <el-option label="OpEX合同" value="2"/>
                    <el-option label="通用合同" value="0"/>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="6">
                <el-form-item
                  label="关联已解除合同:"
                  prop="reliefContractNo"
                >
                  <el-input
                    v-model="creatform.reliefContractNo"
                    placeholder="请输入合同编号进行关联"
                  />
                </el-form-item>
              </el-col> -->
            </el-row>
          </section>
        </div>

        <!-- 签约主体 -->
        <div class="detail-card detail-card-nopadding">
          <header class="card-header">
            <h4 class="second-title">签约主体</h4>
          </header>
          <section class="card-content">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  label="我方签约主体:"
                  prop="ourCompany"
                >
                  <el-select
                    multiple
                    filterable
                    remote
                    placeholder="请输入不少于4个字的主体名称进行查询"
                    :remote-method="searchOurCompany"
                    v-model="creatform.ourCompany"
                    style="width: 100%"
                    ref="ourCompany"
                  >
                    <template v-for="(item, index) of ourCompanyList">
                      <el-option
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                      />
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="对方签约主体:"
                  prop="supplierCompany"
                >
                  <el-select
                    remote
                    :remote-method="searchOurCompany1"
                    multiple
                    filterable
                    placeholder="请输入不少于4个字的主体名称进行查询"
                    v-model="creatform.supplierCompany"
                    style="width: 100%"
                    ref="supplierCompany"
                  >
                    <div slot="" class="option-wrapper">
                      <template>
                        <el-option
                          v-for="(item, index) of supplierCompanyListNew"
                          :key="index"
                          :label="item.name"
                          :value="item.id"
                        >
                          <p class="option-title">
                            <span class="option-name" v-maia-ellipsis>{{ item.name }}</span>
                            <span :class="['tag', item.type]">{{ item.type === 'T02' ? '被投方' : (item.type === 'T03' ? '合作方' : '供方') }}</span>
                          </p>
                        </el-option>
                      </template>
                    </div>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  label="其他签约主体:"
                  prop="otherCompany"
                >
                  <el-select
                    multiple
                    filterable
                    remote
                    placeholder="请输入其他签约主体名称"
                    :remote-method="searchOtherCompany"
                    v-model="creatform.otherCompany"
                    style="width: 100%"
                    ref="otherCompany"
                  >
                    <template v-for="(item, index) of otherCompanyList">
                      <el-option
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                      />
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col
                :span="12"
                v-if="creatform.needPay==='Y'"
              >
                <el-form-item
                  label="收款单位:"
                  prop="payeeCompany"
                >
                  <el-select
                    multiple
                    filterable
                    remote
                    placeholder="请输入收款单位名称"
                    :remote-method="searchPayeeCompany"
                    v-model="creatform.payeeCompany"
                    style="width: 100%"
                    ref="payeeCompany"
                  >
                    <template v-for="(item, index) of payeeCompanyList">
                      <el-option
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                      />
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row> -->
          </section>
        </div>
        <!-- 招采信息 -->
        <div
          class="detail-card detail-card-smallPadding"
        >
          <header class="card-header">
            <h4 class="second-title">招采信息</h4>
          </header>
          <section class="card-content">
            <el-row :gutter="20">
              <el-col :span="18">
                <el-form-item
                  label="招标/比价/直委:"
                  prop="orderMsg"
                >
                  <el-input
                    type="textarea"
                    size="small"
                    :autosize="{ minRows: 2, maxRows: 6 }"
                    maxlength="5000"
                    show-word-limit
                    placeholder="请输入招标/比价/直委说明信息"
                    v-model="creatform.orderMsg"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <Attach
              :label="label2"
              :showRemark="false"
              :buttonName="pickButtonName"
              :fileUploadList="forminingfileList"
              @upload="uploadForminingFile"
            >
            </Attach>
          </section>
        </div>
        <div>
          <!-- 合同金额 -->
          <div class="detail-card detail-card-nopadding">
            <header class="card-header">
              <h4 class="second-title">合同金额</h4>
            </header>
            <section class="card-content">
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item
                    label="币种:"
                    prop="currency"
                  >
                    <el-select
                      v-model="creatform.currency"
                      style="width: 100%"
                      :clearable="true"
                    >
                      <template v-for="(item, index) of currencyList">
                        <el-option
                          :key="index"
                          :label="item.value"
                          :value="item.code"
                        />
                      </template>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item
                    label="合同金额(含税)(A):"
                    prop="contractAmount"
                    class="label-style"
                  >
                    <el-input
                      class="amount-input"
                      placeholder="请输入金额"
                      v-model="creatform.contractAmount"
                      @input="limitInput($event, 'contractAmount')"
                      @keydown.enter.native.prevent="handle"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item
                    label="不计成本金额(B):"
                    prop="costAmount"
                    class="label-style label-style-line"
                  >
                    <el-input
                      class="amount-input"
                      placeholder="请输入金额"
                      v-model="creatform.costAmount"
                      @input="limitInput($event, 'costAmount')"
                      @keydown.enter.native.prevent="handle"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item
                    prop="effContractAmount"
                    class="label-style"
                  >
                    <span slot="label">有效签约金额<br />(C=A-B):</span>
                    <el-input
                      class="amount-input"
                      placeholder="-"
                      v-model="creatform.effContractAmount"
                      disabled
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item
                    label="发票类型:"
                    prop="invoiceType"
                  >
                    <el-select
                      v-model="creatform.invoiceType"
                      style="width: 100%"
                    >
                      <template v-for="(item, index) of invoiceTypeList">
                        <el-option
                          :key="index"
                          :label="item.label"
                          :value="item.code"
                        />
                      </template>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item
                    label="可抵扣税率:"
                    prop="deductibleTaxRate"
                  >
                    <el-input
                      class="amount-input"
                      placeholder="请输入可抵扣税率"
                      v-model="creatform.deductibleTaxRate"
                      @input="limitInput($event, 'deductibleTaxRate')"
                      @keydown.enter.native.prevent="handle"
                    >
                      <i
                        slot="suffix"
                        :style="{'color':'#999','paddingRight': 6+'px'}"
                      > % </i>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item
                    label="可抵扣税额:"
                    prop="deductibleTax"
                  >
                    <el-input
                      class="amount-input"
                      placeholder="-"
                      v-model="creatform.deductibleTax"
                      disabled
                    />
                  </el-form-item>
                </el-col>

                <el-col :span="6">
                  <el-form-item
                    prop="exContractAmount"
                    class="label-style"
                  >
                    <span slot="label">合同金额<br />(不含抵扣税):</span>
                    <el-input
                      v-model="creatform.exContractAmount"
                      class="amount-input"
                      placeholder="-"
                      disabled
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item
                    prop="aboveQuotaType"
                    class="label-style"
                  >
                    <span slot="label">可超额付款<br />比例/金额:</span>
                    <el-select
                      v-model="creatform.aboveQuotaType"
                      style="width: 100%"
                    >
                      <el-option
                        label="按比例"
                        value="RATE"
                      />
                      <el-option
                        label="按金额"
                        value="FIX"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item>
                    <div
                      class="toggle"
                      v-if="creatform.aboveQuotaType == 'RATE'"
                    >
                      <el-input
                        class="amount-input"
                        v-model="creatform.aboveQuotaScale"
                        placeholder="请输入"
                        @input="limitInput($event, 'aboveQuotaScale')"
                        @keydown.enter.native.prevent="handle"
                      >
                        <i
                          slot="suffix"
                          :style="{'color':'#323232','paddingRight': 6+'px'}"
                        > % </i>
                      </el-input>
                    </div>
                    <div
                      class="toggle"
                      v-else
                    >
                      <el-input
                        class="amount-input"
                        v-model="creatform.aboveQuotaAmount"
                        placeholder="请输入"
                        @input="limitInput($event, 'aboveQuotaAmount')"
                        @keydown.enter.native.prevent="handle"
                      >
                      </el-input>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row> -->
            </section>
          </div>
          <!-- 费项分摊 -->
          <div class="detail-card detail-card-css" v-if="creatform.needPay === 'Y'">
            <header class="card-header cost">
              <h4 class="second-title">费项分摊</h4>
              <button
                class="btn btn-primary"
                @click.prevent="addCostList"
              >
                <i class="el-icon-circle-plus-outline iconStyle"></i>
                新增
              </button>
            </header>
            <section class="card-content">
              <el-table
                :data="creatform.costExpenseList"
                ref="costExpenseList"
                style="width: 100%"
                border
                fit
              >
                <div
                  slot="empty"
                  class="empty-box"
                >
                  <button
                    class="btn"
                    @click.prevent="addCostList"
                  >
                    <svg-icon data_iconName="add" className="svg-size"></svg-icon>
                  </button>
                  <div><span>请点击【新增】按钮，添加费项分摊信息</span></div>
                </div>
                <el-table-column
                  label="责任人"
                  prop="operatorNo"
                >
                  <template slot-scope="scope">
                    <el-form-item
                      class="cost-card"
                      :prop="'costExpenseList.'+ scope.$index + '.operatorNo'"
                      :rules="costFormRules.operatorNo"
                    >
                      <el-select
                        filterable
                        v-model="scope.row.operatorNo"
                        size="mini"
                      >
                        <el-option
                          v-for="(item, index) of dutyPeoList"
                          :key="index"
                          :label="item.name"
                          :value="item.id"
                        />
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column
                  label="费项"
                  prop="costItemsId"
                >
                  <template slot-scope="scope">
                    <el-form-item
                      class="cost-card"
                      :prop="'costExpenseList.'+ scope.$index + '.costItemsId'"
                      :rules="costFormRules.costItemsId"
                    >
                      <el-select
                        filterable
                        v-model="scope.row.costItemsId"
                        size="mini"
                      >
                        <el-option
                          v-for="(item, index) of costDetailList"
                          :key="index"
                          :label="item.F_CAPTION"
                          :value="item.FK_KMBM"
                        />
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column
                  label="预算分摊部门"
                  prop="costItemsDept"
                >
                  <template slot-scope="scope">
                    <el-form-item
                      class="cost-card"
                      :prop="'costExpenseList.'+ scope.$index + '.costItemsDept'"
                      :rules="costFormRules.costItemsDept"
                    >
                      <el-select
                        filterable
                        v-model="scope.row.costItemsDept"
                        size="mini"
                      >
                        <el-option
                          v-for="(item, index) of costShareDepartList"
                          :key="index"
                          :label="item.F_CAPTION"
                          :value="item.F_ID"
                        />
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column
                  label="分摊金额(含税)"
                  prop="amount"
                  align="right"
                >
                  <template slot-scope="scope">
                    <el-form-item
                      class="cost-card"
                      :prop="'costExpenseList.'+ scope.$index + '.amount'"
                      :rules="costFormRules.amount"
                    >
                      <el-input
                        size="small"
                        v-model="scope.row.amount"
                        class="amount-input"
                        placeholder="请输入分摊金额"
                        @input="()=> {scope.row.amount = limitInput(scope.row.amount)}"
                        @keydown.enter.native.prevent="handle"
                      />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column
                  label="操作"
                  width="60px"
                  align="left"
                >
                  <template slot-scope="scope">
                    <div>
                      <el-button
                        class="operate-btn"
                        type="text"
                        @click="dealCost(scope.$index, scope.row)"
                      > 删除
                      </el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </section>
          </div>
          <!-- 合同付款约定 -->
          <div class="detail-card" v-if="creatform.needPay === 'Y'">
            <header class="card-header describe">
              <h4 class="second-title">合同付款约定</h4>
              <span>请说明首付款、过程付款、尾款的付款条件、时间及金额</span>
            </header>
            <section class="card-content">
              <el-row>
                <el-col :span="18">
                  <el-form-item
                    label="说明信息:"
                    prop="payAgreement"
                  >
                    <el-input
                      type="textarea"
                      size="small"
                      placeholder="请输入内容"
                      :autosize="{ minRows: 2, maxRows: 6 }"
                      maxlength="5000"
                      show-word-limit
                      v-model="creatform.payAgreement"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </section>
          </div>
        </div>
        <!-- 合同说明 -->
        <div class="detail-card">
          <header class="card-header describe">
            <h4 class="second-title">合同说明</h4>
            <span>请明确本次合同签订的范围及目的，以供审批人直观审批</span>
          </header>
          <section class="card-content">
            <el-row>
              <el-col :span="18">
                <el-form-item
                  label="说明信息:"
                  prop="contractNote"
                >
                  <el-input
                    type="textarea"
                    size="small"
                    placeholder="请输入内容"
                    :autosize="{ minRows: 2, maxRows: 6 }"
                    maxlength="5000"
                    show-word-limit
                    v-model="creatform.contractNote"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </section>
        </div>
        <!-- 文档 -->
        <div class="detail-card no-bottom-border">
          <header class="card-header">
            <h4 class="second-title">文档</h4>
          </header>
          <section class="card-content">
            <Attach
              ref="attach"
              :label="label"
              :prop="'contractFile'"
              :buttonName="buttonName"
              :fileUploadList="creatform.contractFile"
              @upload="uploadFile"
            >
            </Attach>
          </section>
        </div>
      </el-form>
    </section>
    <footer class="footer fix_footer">
      <div class="btnBox">
        <button
          class="btn btn-primary btn-temporary"
          @click="conStorage"
          :disabled="storageDisabled"
          :class="storageDisabled?'storage_disabled':''"
        >
          暂存
        </button>
        <button
          class="btn btn-primary"
          @click="approval"
          id="nextStep"
        >
          下一步
        </button>
      </div>
    </footer>
  </div>
</template>

<script>
import Attach from "@/components/common/Attach.vue"
import utils from '@/utils/index'
import getCurrentUser from './mixins/getCurrentUser'
export default {
  name: "creatContract",
  components: {
    Attach,
  },
  mixins: [ getCurrentUser ],
  data() {
    return {
      label: "附件：",
      label2: "",
      buttonName:"上传附件",
      pickButtonName: "上传招采附件",
      dateTip: false, // 日期提示
      rightNow: "", // 当前日期
      contractID: "", // 存为草稿之后会返回一个合同ID
      storageDisabled: false, // 暂存按钮禁用

      // 合同表单数据
      creatform: {
        contractName: "", // 合同名称
        contractType: "COMMON", //合同类型
        needPay: "Y", // 是否需要付款
        supplyStoreFlag: '1',
        projectGoalFlag: "N", // 关联项目，默认非项目目的
        contractDate: "", // 合同经办日期
        ourCompany: [], // 我方签约主体
        supplierCompany: [], // 对方签约主体
        currency: "CNY", // 币种
        aboveQuotaType: "RATE",
        costExpenseList: [], // 费项列表
        attachFileList: [],
        contractFile: [], //合同附件
        forminingfileList: [],
        relevanceProjectInfo: [], // 关联项目
        //contractDept:"", // 经办人部门
        fixContractType:'0' //具体合同类型
      },
      activeName:"0", // 关联项目下拉选项默认全部
      // 合同表单验证规则
      formRules: {
        contractName: [{ required: true, message: "请输入合同名称" }],
        contractDate: [{ required: true, message: "请选择经办时间" }],
        needPay: [{ required: true, message: "请选择是否需要付款" }],
        ourCompany: [{ required: true, message: "请选择我方签约主体" }],
        supplierCompany: [{ required: true, message: "请选择对方签约主体" }],
        currency: [{ required: true, message: "请选择币种" }],
        contractAmount: [{ required: true, message: "请输入合同金额" }],
        invoiceType: [{ required: true, message: "请选择发票类型" }],
        projectGoalFlag: [{ required: true, message: "请选择关联项目" }],
        relevanceProjectInfo: [{ required: true, message: "请选择关联项目" }],
        contractFile: [{ required: true, message: "请上传附件" }],
        supplyStoreFlag: [{ required: true, message: "请选择是否供方合同" }],
        fixContractType: [{ required: true, message: "请选择具体合同类型" }],
        kgStage: [{ required: true, message: "请选择所属阶段" }]
        // contractDept: [{ required: true, message: "请选择经办部门" }]
      },
      // 费项表单验证
      costFormRules:{
        operatorNo: [{ required: true, message: "请选择责任人" }],
        costItemsId: [{ required: true, message: "请选择费项" }],
        costItemsDept: [{ required: true, message: "请选择费项分摊部门" }],
        amount: [{ required: true, message: "请输入金额"}]
      },
      // 合同类型列表
      contypeList: [
        {
          label: "通用合同",
          value: "COMMON"
        },
        {
          label: "礼品采购",
          value: "GIFT"
        },
        {
          label: "品牌活动合同",
          value: "ACTIVITY"
        },
        {
          label: "董事长事务合同",
          value: "BUSINESS"
        }
      ],
      // 经办人所属部门列表
      // orgList:[],
      // 形成方式列表
      forTypeList: [],
      // 币种列表
      currencyList: [],
      // 发票类型列表
      invoiceTypeList: [],

      // 我方签约主体列表
      ourCompanyList: [],
      // 对方签约主体列表
      supplierCompanyList: [],
      supplierCompanyListNew: [],
      // 其他签约主体列表
      // otherCompanyList: [],
      // 收款单位列表
      //payeeCompanyList: [],

      // 所有主体集合
      subList: [],

      // 费项责任人选择列表
      dutyPeoList: [],
      // 费项明细选择列表
      costDetailList: [],
      // 费项预算分摊部门列表
      costShareDepartList: [],

      // 招采信息附件列表
      forminingfileList: [],
      // 文件上传列表
      contractFileList: [],

      //合并后的附件
      totalFileList: [],

      // 关联项目列表-所有
      correlateListAll: [],
      // 关联项目列表-直投
      // correlateListDire: [],
      // 关联项目列表-债券
      correlateListBond: [],
      // 关联项目列表-控股
      correlateListHolding: [],
      // 关联项目列表-一级基金
      correlateListStairFund: [],
      // 关联项目列表-二级基金
      correlateListSecondaryFund: [],
      // 关联项目列表-股票
      correlateListStock: [],
      // 关联项目列表-固定资产
      correlateListFixed: [],
      // 关联项目列表-一级直投线索(项目源)
      correlateListClew: [],

      // 关联项目loading
      correlateLoading: false,

      loading: null, // 全局loading

      // 校验供方合同是否选择供方主体
      checkContractNext: true,
      supplierIdArray: []
    };
  },
  mounted() {
    this.moneyCheck(this.creatform.needPay)
  },
  methods: {
    handle() {},
    // 是否供方合同
    // 获取所有供方主体id存为数组
    getSupplierIdArray(data) {
      let arr = [...data.T01,...data.T02]
      arr.forEach(item => {
        if (item.type === 'T04') {
          this.supplierIdArray.push(item.id)
        }
      })
    },
    // 校验是否供方合同并做处理
    checkContractSource() {
      this.checkContractNext = true
      if (this.creatform.supplyStoreFlag === '1' && this.creatform.supplierCompany) {
        this.creatform.supplierCompany.forEach(item => {
          if(this.supplierIdArray && this.supplierIdArray.includes(item)) {
            this.checkContractNext = false
          }
        })
      }
      if (this.creatform.supplyStoreFlag === '1' && this.creatform.addSupplierCompany) {
        this.creatform.addSupplierCompany.forEach(item => {
          if(this.supplierIdArray && this.supplierIdArray.includes(item)) {
            this.checkContractNext = false
          }
        })
      }
      if (this.creatform.supplyStoreFlag === '0') {
        this.checkContractNext = false
      }
    },
    // 改变合同来源
    changeContractSource(val = '1') {
      if (val === '0') {
        this.supplierCompanyListNew = []
        this.supplierCompanyList.forEach(item => {
          if (item.type === 'T02' || item.type === 'T03' ) {
            this.supplierCompanyListNew.push(item)
          }
        })
        if (this.creatform.supplierCompany) {
          this.creatform.supplierCompany.forEach((items, index) => {
            if (this.supplierIdArray && this.supplierIdArray.includes(items)) {
              this.creatform.supplierCompany.splice(index, 1)
            }
          })
        }
      } else {
        this.supplierCompanyListNew = this.supplierCompanyList
      }
    },
    // 金额币种是否必填
    moneyCheck(val) {
      if (val !== 'Y') {
        this.formRules.invoiceType[0].required = false
        if (this.creatform.currency) {
          this.creatform.currency = ''
        }
        if (this.creatform.contractAmount) {
          this.creatform.contractAmount = ''
        }
      } else {
        this.formRules.invoiceType[0].required = true
        if (!this.creatform.currency) {
          this.creatform.currency = 'CNY'
        }
      }
      if (val !== 'Y' && !this.creatform.contractAmount && !this.creatform.currency) {
        this.formRules.currency[0].required = false
        this.formRules.contractAmount[0].required = false
      } else if (val !== 'Y' && !this.creatform.contractAmount && this.creatform.currency) {
        this.formRules.currency[0].required = false
        this.formRules.contractAmount[0].required = true
      } else {
        this.formRules.currency[0].required = true
        this.formRules.contractAmount[0].required = true
      }
    },
    //暂存
    conStorage() {
      if(!this.creatform.contractName.trim()) {
        this.$message.error('合同名称不能为空')
        return
      }

      this.storageDisabled = true
      this.saveContract("draft").then(res =>{
        this.contractID = res
        this.$message({
          type: "success",
          message: "暂存成功",
          duration: 1000,
        })
      })

    },
    //从子组件获取合同类附件列表
    uploadFile(params) {
      if (params.length > 0) {
        this.$refs.attach?.$refs.file_rule?.clearValidate()
      }
      params.forEach(item => {
        item.fileCategory = "CONTRACT";
      });
      this.creatform.contractFile = params;
    },
    //招采类附件列表
    uploadForminingFile(params) {
      params.forEach(item => {
        item.fileCategory = "RECRUIT";
      });
      this.forminingfileList = params;
    },
    // 获取当前日期
    getRightNowDate() {
      let nowDate = new Date();
      let y = nowDate.getFullYear();
      let m = (nowDate.getMonth() + 1).toString();
      let d = nowDate.getDate().toString();

      m = m.length == 1 ? "0" + m : m;
      d = d.length == 1 ? "0" + d : d;

      this.rightNow = y + "-" + m + "-" + d;
      this.creatform.contractDate = this.rightNow;
    },
    // 获取主体信息列表
    getSubList() {
      this.$request.post("/enterprise/queryEnterpriseAll").then(res => {
        if (res.code === "200") {
          let data = res.data
          this.subList = data
          // this.ourCompanyList = data.T01;
          // this.supplierCompanyList = data.T02
          this.getSupplierIdArray(data)
          // 是否供方合同
          this.changeContractSource('1')
          // this.otherCompanyList = data.T03;
          // this.payeeCompanyList = [...data.T02, ...data.T03];
        }
      }).finally(() => {
        // 关闭全局loading
        if(this.loading) {
          this.loading.close()
        }
      });
    },
    // // 获取费项列表责任人
    getCostDutePeo() {
      this.$request.post("/employee/queryEmployeeAll?scope=").then(res => {
        if (res.code === "200") {
          this.dutyPeoList = res.data;
        }
      });
    },
    // // 获取费项预算部门
    getCostDep() {
      this.$request.post("/cost/queryCostItemDept").then(res => {
        if (res.code === "200") {
          this.costShareDepartList = res.data;
        }
      });
    },
    // // 获取费项选择列表
    getCostList() {
      this.$request.post("/cost/queryCostItem").then(res => {
        if (res.code === "200") {
          this.costDetailList = res.data;
        }
      });
    },
    // 经办日期更改后
    dateChange(val) {
      if (val < this.rightNow) {
        this.dateTip = true;
      } else {
        this.dateTip = false;
      }
    },
    // 费项行数据处理
    dealCost(index, row) {
      // 删除当前这条数据
      this.creatform.costExpenseList.splice(index, 1);
    },
    // 处理金额输入框的校验
    limitInput(value, name) {
      return this.$limitRule(value,name,this.creatform)
    },
    // 增加费项行
    addCostList() {
      this.creatform.costExpenseList.unshift({})
    },
    // 发起审批
    approval() {
      this.checkContractSource()
      if (this.checkContractNext) {
        this.$message.error('供方合同请至少选择一个供方主体')
        return
      }
      if(this.creatform.contractName.trim() === '') {
        this.$message({
          type: "error",
          message: "合同名称不能为空"
        })
        return
      }
      this.$refs["creatform"].validate(valid => {
        if(this.cancle) {
          this.cancle()
        }
        if (valid) {
          // 打开全局loading
          this.loading = this.$loading({
            fullscreen: true,
            background: "rgba(255, 255, 255, 0.8)",
          })
          this.saveContract('contract').then(res => {
            this.$message({
              type: "success",
              message: "保存成功，即将跳转审批页面",
              duration: 1500,
              onClose: () => {
                var pWin = window.open('_blank')
                pWin.location = res
                window.opener = null;
                window.open("about:blank", "_top").close()
                // if (pWin != null) {
                //   // 关闭当前页

                // } else {
                //   setTimeout(() => {
                //     if (pWin != null) {
                //       window.opener = null;
                //       window.open("about:blank", "_top").close()
                //     } else {
                //       this.$message.warning('请求超时，请重新点击')
                //     }
                //   }, 500);
                // }
              }
            });
          })
        } else {
          this.$message({
            type: "error",
            message: "请填写必填项",
            duration: 1500
          });
        }
      });
    },

    // 保存数据调用接口
    saveContract(type) {
      //合并招采附件，合同附件，去除保存不成功附件
      this.totalFileList = this.creatform.contractFile.concat(
        this.forminingfileList
      )
      var temp = []
      this.totalFileList.forEach(item => {
        let index = temp.findIndex(v => v.uid === item.uid)
        if ("success" === item.status && "-1" == index) {
          //去掉递归子文件
          if(item.partFiles) {
            item.partFiles = ''
          }
          temp = [
            {...item},
            ...temp
          ]
        }
      })
      this.creatform.attachFileList = temp

      // 处理费项数据结构
      if(this.creatform.costExpenseList.length !== 0) {
        let costList = this.creatform.costExpenseList
        costList.forEach(item => {
          this.costDetailList.forEach(it =>{
            if(it.FK_KMBM === item.costItemsId) {
              item.costItemsName = it.F_CAPTION
            }
          })
          this.costShareDepartList.forEach(it => {
            if(it.F_ID === item.costItemsDept) {
              item.costItemsDeptName = it.F_CAPTION
            }
          })
        })
      }

      this.creatform.contractName = this.creatform.contractName.trim()

      let params = {...this.creatform}

      params.ourCompany = this.$formatSubList("ourCompany", this.creatform)
      params.supplierCompany = this.$formatSubList("supplierCompany", this.creatform)
      // 只有固资给后端穿这个字段
      params.fixContractType = this.creatform.projectGoalFlag == 'Y' && this.projectTypeList.indexOf('7') != -1 && this.creatform.contractType == 'COMMON' ? this.creatform.fixContractType : null
      params.kgStage = this.creatform.projectGoalFlag == 'Y' && this.projectTypeList.indexOf('3') != -1 && this.creatform.contractType == 'COMMON'? this.creatform.kgStage : null
      // params.otherCompany = this.$formatSubList("otherCompany", this.creatform)
      // params.payeeCompany = this.$formatSubList("payeeCompany", this.creatform)

      if(this.creatform.relevanceProjectInfo.length !== 0) {
        params.relevanceProjectInfo = this.creatform.relevanceProjectInfo.join(',')
      } else {
        params.relevanceProjectInfo = ''
      }


      if(type === "draft") {
        params.contractStatus = 'draft'
      }else {
        params.contractStatus = ''
      }
      if(this.contractID) {
        params.id = this.contractID
      }
      // console.log('params', params)
      // debugger
      return new Promise(resolve => {
        this.$request.post('/contract/addContract',{params}).then(res => {
          if(this.loading) {
            // 关闭全局loading
            this.loading.close()
          }
          this.storageDisabled = false
          if(res.code === "200" && res.data ) {
            resolve(res.data)
          }else {
            this.$message({
              type: "error",
              message: res.message
            })
          }
        })
      })
    },

    // 切换是否关联项目
    correlateOptions(val){
      if(val === 'Y' && this.correlateListAll.length == 0) {
        this.getCorrelateList()
      }
    },

    // 切换关联项目类别
    correlateClick(tab,event){
      this.getCorrelateList(tab.name)
    },

    // 获取关联项目
    getCorrelateList(correlateType) {
      this.correlateLoading = true
      let params = {
        // TODO 临时更改
        type : correlateType || '0'
      }
      this.$request.post('/project/queryProjectList',{params}).then(res =>{
        this.correlateLoading = false
        if(res.code === '200' && res.data) {
          let data = res.data
          this.correlateListAll = data
          //  直投
          // this.correlateListDire = data.filter(item =>{
          //   return item.type === '1'
          // })
          // 债券
          this.correlateListBond = data.filter(item =>{
            return item.type === '2'
          })
          // 控股
          this.correlateListHolding = data.filter(item =>{
            return item.type === '3'
          })
          // 一级基金
          this.correlateListStairFund = data.filter(item =>{
            return item.type === '4'
          })
          // 二级基金
          this.correlateListSecondaryFund = data.filter(item =>{
            return item.type === '5'
          })
          // 股票
          this.correlateListStock = data.filter(item =>{
            return item.type === '6'
          })
          // 固定资产
          this.correlateListFixed = data.filter(item =>{
            return item.type === '7'
          })
          // 直投线索
          this.correlateListClew = data.filter(item =>{
            return item.type === '8'
          })
        }
      })
    },
    // 远程搜索我方主体
    searchOurCompany(keyWords){
      this.ourCompanyList = this.$optionDataFilter(keyWords,this.subList.T01)
    },
    searchOurCompany1(keyWords){
      this.supplierCompanyListNew = this.$optionDataFilter(keyWords,this.subList.T02, { limit: 2, nameKey: 'name' })
    },
  },
  computed: {
    listenChange () {
        const {contractAmount, costAmount, exContractAmount, deductibleTaxRate} = this.creatform
        return {contractAmount, costAmount, exContractAmount, deductibleTaxRate}
      },
    projectTypeList() {
      let arr = this.creatform.relevanceProjectInfo.map(element=> {
        let target = this.correlateListAll.find(pro=> pro.id ==element)
        if(target) {
          return target.type
        }
      })
      return arr
    }
  },
  watch: {
    listenChange: {
      handler(val, oldVal) {
        //contractAmount  合同金额（含税）
        //effContractAmount  有效签约金额
        //costAmount  不急成本金额
        //deductibleTax  可抵扣税额
        //exContractAmount  合同金额（不含抵扣税）
        //deductibleTaxRate  可抵扣税率

        if (val.contractAmount !== undefined && val.contractAmount !== "") {
          // 计算有效签约金额

          if (val.costAmount) {
            if(parseFloat(val.contractAmount) >= parseFloat(val.costAmount)) {
              this.creatform.effContractAmount = utils.BigNumberCacl(this.creatform.contractAmount, this.creatform.costAmount, '-')
            }else {
              this.$message.warning('有效签约金额不能为负数')
              this.creatform.effContractAmount =  utils.BigNumberCacl(this.creatform.contractAmount, '0', '-')
              this.creatform.costAmount = ''
            }
          } else {
            this.creatform.effContractAmount =
              utils.BigNumberCacl(this.creatform.contractAmount, '0', '-')
          }

          // 计算可抵扣税额 合同金额（不含抵扣税）
          if (val.deductibleTaxRate) {
            // let tax = this.creatform.contractAmount / (1 + (val.deductibleTaxRate) / 100) * (val.deductibleTaxRate / 100)
            let middle = utils.BigNumberCacl(this.creatform.contractAmount, (1 + (val.deductibleTaxRate) / 100), '/')
            let tax = utils.BigNumberCacl((val.deductibleTaxRate / 100), middle, '*')
            this.creatform.deductibleTax = tax

            this.creatform.exContractAmount =
              utils.BigNumberCacl(this.creatform.contractAmount, this.creatform.deductibleTax, '-')
          } else {
            this.creatform.exContractAmount = utils.BigNumberCacl(this.creatform.contractAmount, '0', '-')
            this.creatform.deductibleTax = null
          }
        } else {
          this.creatform.effContractAmount = null
          this.creatform.exContractAmount = null
          this.creatform.deductibleTax = null
        }
      },
      deep: true
    },
    'creatform.needPay': function(val) {
      this.moneyCheck(val)
    },
    'creatform.currency': function(val) {
      if (this.creatform.needPay === 'N') {
        if (val) {
          this.formRules.contractAmount[0].required = true
        } else {
          this.formRules.contractAmount[0].required = false
        }
      }
    },
    'creatform.contractAmount': function(val) {
      if (this.creatform.needPay === 'N') {
        if (val) {
          this.formRules.currency[0].required = true
        } else {
          this.formRules.currency[0].required = false
        }
      }
    },
  },
  created() {
    // 打开全局loading
    this.loading = this.$loading({ 
      fullscreen: true,
      background: "rgba(255, 255, 255, 0.8)",
      text: "正在加载数据..."
    })

    this.currencyList = JSON.parse(localStorage.getItem("currencyList"))
    this.invoiceTypeList = JSON.parse(localStorage.getItem("invoiceTypeList"))
    this.forTypeList = JSON.parse(localStorage.getItem("forTypeList"))

    this.getRightNowDate()
    this.getSubList()
    this.getCostDutePeo()
    this.getCostDep()
    this.getCostList()
    // this.getCurrentOrg()
  },

};
</script>
<style lang="less" scoped>
.option-wrapper{
  ::v-deep .el-select-dropdown__item.selected::after{
    top: 0;
    color: #997236;
  }
  .option-title{
    display: flex;
    justify-content: space-between;
    padding-right: 30px;
    box-sizing: border-box;
    align-items: center;
    span.option-name{
      display: inline-block;
      width: 336px;
      color: #606266;
      font-size: 14px;
    }
    .tag{
      width: 48px;
      height: 18px;
      text-align: center;
      line-height: 18px;
      background: #F7EBD4;
      border-radius: 2px;
      font-size: 10px;
      &.T02{
        color: #7AA2F4 !important;
        background: #EEF3FD;
      }
      &.T03{
        color: #997236 !important;
        background: #F7EBD4;
      }
      &.T04{
        color: #50AC94 !important;
        background: #E0F7F1;
      }
    }
  }
}

.page-box {
  .list-title {
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    line-height: 40px;
    padding: 6px 12px;
    border-bottom: 1px solid #eaeaea;
    h4{
      line-height: 36px;
      height: 36px;
    }
  }
  .detail-card {
    .card-content {
      /deep/ .el-radio__input.is-checked .el-radio__inner {
        border-color: #efd193;
        background: #efd193;

        &::after {
          background-color: #323232;
          border-radius: 50%;
        }
      }

      .el-radio__label {
        color: #323232;
      }
      /deep/ .el-radio__input.is-checked + .el-radio__label {
        color: #323232;
      }
    }
    .describe {
      h4,
      span {
        display: inline-block;
      }
      span {
        color: #999999;
        font-size: 12px;
        margin-left: 6px;
      }
    }

    .correlate {
      margin-left: -120px;

      .correlate-options {
        width: 580px;
      }

      .correlate-options /deep/ .el-tabs__header {
        padding: 0 6px 0 0 !important;
      }
      .correlate-options /deep/ .el-tabs__item {
        color: #5b5b5b;
        font-weight: 600;
        width: 70px !important;
      }

      .options-selects {
        .selected {
          color: #997236;

          &::after {
            top: 0;
          }
        }

        .options-line {
          box-sizing: border-box;
          max-width: 590px;
          height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-right: 20px;

          .options-name {
            display: inline-block;
            height: 100%;
            flex: 1;
          }
          .options-type {
            display: inline-block;
            width: 60px;
            height: 22px;
            line-height: 22px;
            background-color: #f7ebd4;
            text-align: center;
            color: #997236;
            font-size: 10px;
          }
        }
      }
    }

    .date-tip {
      font-size: 12px;
      position: relative;
      top: -8px;
      color: #808898;
    }
  }
  .detail-card-nopadding{
    padding: 18px 0 0;
  }
  .detail-card-smallPadding{
    padding: 18px 0 6px;
  }
  .footer {
    .storage_disabled {
      &:hover {
        cursor: not-allowed;
      }
    }
  }
}
::v-deep .el-input__inner{
  border-radius: 2px;
}
::v-deep input::-webkit-input-placeholder {
  font-weight: 400;
}
.svg-size{
  font-size: 24px;
}
#project-goal{
  ::v-deep .el-select .el-input.is-focus .el-input__inner {
    border: 1px solid #F0D8A8;
  }
  ::v-deep .el-select-dropdown__item.selected{
    color:#997236 !important;
  }
  ::v-deep .el-select-dropdown__item:hover{
    background: #FDFBF6;
  }
  ::v-deep .el-select-dropdown{
    margin-top: 12px !important;
    border-radius: 2px !important;
    border: 1px solid #DCDCDC !important;
  }
}
.detail-card-css{
  ::v-deep .cell {
    padding: 0 12px !important;
  }
  ::v-deep .el-select--mini{
    width: 100%;
  }
  ::v-deep .el-input--mini .el-input__inner{
    height: 24px;
    width: 100%;
  }
  ::v-deep .el-input__inner{
    height: 24px;
  }
}
.label-style-line{
  ::v-deep .el-form-item__label{
    line-height: 32px !important;
  }
}
// #project-goal-content{
//
// }
</style>
