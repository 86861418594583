<template>
  <!-- 标题组件 -->
  <div class="contentBox">
    <el-row>
      <el-col :span="18">
        <el-form-item
          ref="file_rule"
          :label="label"
          :prop="prop"
          class="attachForm"
        >
          <halo-oss-upload
            ref="fileUp2D"
            :limit="200"
            :httpServer="path"
            :castgc="cas"
            oss-app-name="shcms"
            storageMode="minio"
            :showFileList="false"
            :useVersion="false"
            @before-upload="beforeUpload"
            @on-change="fileUploadChange"
            @on-success="fileUploadSuccess"
            @on-error="fileUploadError"
            @on-exceed="fileExceed"
          >
            <button
              class="btn btn-lucency"
              :style="{marginLeft:'0'}"
              @click="preventCode"
            >
              <!-- <i class="el-icon-upload2" ></i> -->
              <svg-icon data_iconName="upload" className="upload-icon"></svg-icon>
              {{buttonName}}
            </button>
          </halo-oss-upload>

        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <!-- 文件上传列表 -->
        <div
          class="uploadFileList"
          v-if="showFileUploadList"
        >
          <ul>
            <!-- <li
              class="fileLine"
              v-for="(item, index) in fileList"
              :key="index"
            >
              <div
                class="filename"
                v-if="['uploading', 'success'].includes(item.status)"
              >
                <p class="textLine">
                  <i class="el-icon-document"></i>
                  <span class="text">
                    <p target="_blank">
                      {{ formateFileName(item) }}
                    </p>
                  </span>
                  <span
                    class="time"
                    v-if="item.status === 'uploading'"
                  >{{ explain }}</span>
                  <span
                    class="time"
                    v-if="item.status === 'success'"
                  >
                    {{ item.bizCreateDateStr || dateFormat(item.startTime) }}
                  </span>
                  <span
                    v-if="!showRemark"
                    class="delete-icon"
                    @click="cancelUpload(item)"
                  > x </span>
                </p>
                <p
                  class="processLine"
                  v-if="item.status === 'uploading'"
                >
                  <el-progress
                    :percentage="getPercentage(item)"
                    :stroke-width="4"
                    color="#71C251"
                  ></el-progress>
                </p>
              </div>
              <div
                class="fileNote"
                v-if="['uploading', 'success'].includes(item.status) && showRemark"
              >
                <el-input
                  placeholder="附件备注"
                  v-model="item.fileNote"
                  @input="modifyNote($event, item)"
                ></el-input>
              </div>
              <div
                class="fileDelete"
                v-if="['uploading', 'success'].includes(item.status) && showRemark"
              >
                <i
                  class="el-icon-remove-outline deleteBtn"
                  @click="cancelUpload(item)"
                ></i>
              </div>
            </li> -->
            <li
              class="fileLine"
              v-for="(item, index) in fileList"
              :key="index"
            >
              <el-row>
                <el-col :span="16">
                  <div
                    class="filename"
                    v-if="['uploading', 'success'].includes(item.status)"
                  >
                    <p class="textLine">
                      <i class="el-icon-document"></i>
                      <span class="text">
                        <p class="file-name">
                          {{ formateFileName(item) }}
                        </p>
                        <span class="suffix">{{ "." + item.fileType }}</span>

                      </span>
                      <span
                        class="time"
                        v-if="item.status === 'uploading'"
                      >{{ explain }}</span>
                      <span
                        class="time"
                        v-if="item.status === 'success'"
                      >
                        {{ item.bizCreateDateStr || dateFormat(item.startTime) }}
                      </span>
                      <span
                        v-if="!showRemark"
                        class="delete-icon"
                        @click="cancelUpload(item)"
                      > x </span>
                    </p>
                    <p
                      class="processLine"
                      v-if="item.status === 'uploading'"
                    >
                      <el-progress
                        :percentage="getPercentage(item)"
                        :stroke-width="4"
                        color="#71C251"
                      ></el-progress>
                    </p>
                  </div>
                </el-col>
                <el-col :span="7">
                  <div
                    class="fileNote"
                    v-if="['uploading', 'success'].includes(item.status) && showRemark"
                  >
                    <el-input
                      placeholder="附件备注"
                      maxlength="5000"
                      show-word-limit
                      v-model="item.fileNote"
                      @input="modifyNote($event, item)"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="1">
                  <div
                    class="fileDelete"
                    v-if="['uploading', 'success'].includes(item.status) && showRemark"
                  >
                    <i
                      class="el-icon-remove-outline deleteBtn"
                      @click="cancelUpload(item)"
                    ></i>
                  </div>
                </el-col>
              </el-row>

            </li>
          </ul>
        </div>
        <!-- 附件上传文件格式提示 -->
        <div class="information-limit" :style="{
                color: '#999999',
                'font-size': '12px',
                'padding-left': '115px',
                }">
          <i class="el-icon-warning-outline"></i>
          支持：.doc .docx .ppt .pptx .pdf .jpg .jpeg .png .xlsx .xls
          .rar .zip 邮件文件,单个文件不能大于200M。
        </div>
      </el-col>
    </el-row>
    <slot name="subTitle"></slot>
  </div>
</template>

<script>
import UploadMixin from '@/api/UploadMixin'
export default {
  name: "Attach",
  mixins: [UploadMixin],
  props: {
      prop: {
      type: String,
      default: "",
    },
    //是否展示备注
    showRemark: {
      type: Boolean,
      default: true,
    },
    //标签名
    label: {
      type: String,
      default: "",
    },
    //按钮名
    buttonName: {
      type: String,
      default: "上传文件",
    },
    //文件列表
    fileUploadList: {
      type: Array,
      default: ()=>{
        return []
      },
    },
  },
  computed: {
    path(){
      let isProduction = this.$settings.isProduction
      if (isProduction) {
        // 生产
        return 'https://cms-doc-usa.platanus.com/openapi'
      } else {
        // uat
        return 'https://cms-doc-uat-usa.platanus.com/openapi'
      }
    },
    cas(){
      return this.$store.state.CASTGC
    },
  },
  data() {
    return {
      fileList: [],
      showFileUploadPanel: false,
      showFileUploadList: false, //
      pagination: {},
      currentDoc: {},
      // 文件上传时的说明文字
      explain: "正在上传，请稍候...",
    };
  },
  watch: {
    fileUploadList: {
      handler(newVal){
        this.fileList = newVal
        if(newVal){
          this.setShowFileUploadList(true)
          this.setShowFileUploadPanel(true)
        }
      },
      immediate: true,
    },
    fileList: {
      handler(newVal){
        this.$emit('upload', newVal)
      },
      immediate: true,
    }
  },
  methods: {
    // 上传文件取消button默认事件
    preventCode(e) {
      e.preventDefault();
    },
    //百分比
    getPercentage(item) {
      return Number(Number(item.percentage + '').toFixed(1))
    },
    setShowFileUploadPanel(data) {
      this.showFileUploadPanel = data
    },
    setFileUploadList(data) {
      this.fileList = data
    },
    addOneFile(data) {
      console.log(data)
      data.fileServicePath = data.versionId
        data.docLibId = data.fileId
        this.fileList = [
          {...data},
          ...this.fileList
        ]
    },
    updateOneFile(data) {
      if(data.fileId) {
        data.docLibId = data.fileId
      }
      const index = this.fileList.findIndex(v => v.uid === data.uid)
      if (index !== -1 && data.statusFlag !== 'cancel') {
        this.fileList.splice(index, 1, data)
      }
    },
    removeOneFile(index) {
        this.fileList.splice(index, 1)
    },
    setShowFileUploadList(data) {
        this.showFileUploadList = data
    },
    modifyNote(event, file){
      file.fileNote = event
      this.updateOneFile(file)
	  },
    dateFormat(date){
      return date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate()
    },
    formateFileName(file) {
      let fullName = file.fileRealName
      let index = fullName.lastIndexOf(".")
      let name = fullName.slice(0, index)
      return name
    },

    //检查是否重名
    checkRepeatName(fileName){
      var index = this.fileList.findIndex(item => item.fileRealName=== fileName)
      return index !== -1
    },

    // 上传文件取消button默认事件
    // preventCode(e) {
    //   e.preventDefault();
    // },

  },
};
</script>

<style lang="less" scoped>
::v-deep .el-input__count-inner{
  position: absolute;
  right: -6px;
  top: 33px;
}
.contentBox {
  .attachForm {
    margin-bottom: 0;
    .upload-icon{
      font-size: 24px;
      vertical-align: middle;
      margin-bottom: 2px;
    }
  }
  .attachForm/deep/.el-form-item__label {
    width: 115px !important;
  }
}

.halo-upload {
  display: inline-block;
}

.uploadFileList {
  margin-left: 115px;
  .fileLine {
    margin-top: 8px;
    &:first-child{
      margin-top: 6px;
    }
    .filename {
      box-sizing: border-box;
      padding: 0 12px;
      min-height: 30px;
      border: 1px solid #d6d6d6;
      border-radius: 2px;
      margin-top: 3px;
      .textLine {
        height: 26px;
        display: flex;
        align-items: center;
        i {
          color: #999999;
        }
        .text {
          flex: 1;
          margin-left: 10px;
          height: 20px;
          line-height: 20px;
          display: flex;

          .file-name {
            display: inline-block;
            max-width: 530px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .suffix {
            width: 24px;
          }
        }
        .time {
          width: 120px;
          text-align: center;
          color: #999999;
        }
        .delete-icon {
          box-sizing: border-box;
          width: 20px;
          height: 20px;
          color: #999999;
          font-size: 14px;
          line-height: 18px;
          text-align: center;
          &:hover {
            cursor: pointer;
          }
        }
      }
      .processLine {
        margin-left: 20px;
        width: 100%;
      }
    }
    .fileNote {
      height: 40px;
      margin-left: 12px;
    }
    .fileDelete {
      width: 40px;
      height: 40px;
      text-align: center;
      margin-left: 5px;
      .deleteBtn {
        color: #fa5f87;
        font-size: 26px;
        line-height: 40px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
::v-deep .el-input__inner{
  height: 30px;
}
</style>
