export default {
  data() {
    return {
      currentUserName: ''
    }
  },
  methods: {
    // 获取当前登录人
    getCurrentUser() {
      this.$request.post("/employee/getCurrUser").then(res =>{
        if(res.code === '200' && res.data) {
          this.currentUserName = res.data.currUserName
        }
      })
    }
  },
  mounted() {
    this.getCurrentUser()
  }
}
